.mainContact{
    overflow-x: hidden;
}
.contactImage{
    height: 400px;
}
.officeImage{
    height: 400px;
    width:600px;
}
@media(max-width: 1024px){
    .contactImage{
        height: 350px;
    }
    .officeImage{
        height: 150px;
        width:250px;
    }
}
@media(max-width: 714px){
    .contactImage{
        height: 250px;
    }
    .officeImage{
        height: 200px;
        width:320px;
    }
}