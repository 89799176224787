.businessImage{
    height: 400px;
}
.posts{
    width: 800px;
    height: 500px;
    object-fit: cover;
}
#news{
    overflow-x: hidden;
}
#homeNews{
    display: flex;
    align-items: center;
    height: 70vh;
    background: linear-gradient(rgb(0,0,0,0.7), rgb(0,0,0,0.7)), url("https://media.istockphoto.com/id/1365436662/photo/successful-partnership.jpg?b=1&s=170667a&w=0&k=20&c=u9NYd0qY266RW0x1nB5ng8scAJZ5LoftacwhtqD-v58=");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
}
.newsText{
    display: block;
    color: white;
    align-items: center;
}
@media(max-width: 767px){
    .businessImage{
        height: 400px;
    }
    .posts{
        width: 600px;
        height: 400px;
        object-fit: cover;
    }
}
@media(max-width: 574px){
    .businessImage{
        height: 300px;
    }
    .posts{
        width: 400px;
        height: 250px;
        object-fit: cover;
    }
}