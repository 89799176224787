#product{
    display: flex;
    align-items: center;
    height: 90vh;
    background: linear-gradient(rgb(0,0,0,0.3), rgb(0,0,0,0.3)), url("https://images.unsplash.com/photo-1416339276121-ba1dfa199912?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fG1lcmNoYW5kaXNpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    }

    .productImage{
        width: 500px;
        height: 350px;
        border-radius: 10px;
    }
    @media(max-width: 767px){
        .productImage{
            width:550px;
        }
    }
    @media(max-width: 574px){
        .productImage{
            width:350px;
            height:250px;
        }
    }
