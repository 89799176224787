.container{
max-width: 1170px;
margin:auto;
}
.row{
display: flex;
flex-wrap: wrap;
}
ul{
list-style: none;
}
.footer{
background-color:  #283b8e;
color: black;
padding: 20px 0;
}
.footer-col{
width: 23%;
padding: 0 15px;
        }
.footer-col h4{
font-size: 18px;
color: white;
text-transform: capitalize;
margin-bottom: 35px;
font-weight: 500;
position: relative;
        }
.footer-col h4::before{
content: '';
position: absolute;
left:0;
bottom: -10px;
background-color: rgb(198, 67, 19);
height: 2px;
box-sizing: border-box;
width: 50px;
        }
.footer-col ul li:not(:last-child){
margin-bottom: 10px;
        }
.footer-col ul li a{
font-size: 16px;
text-transform: capitalize;
color: white;
text-decoration: none;
font-weight: 400;
display: block;
transition: all 0.3s ease;
        }
.footer-col ul li a:hover{
color: rgb(198, 67, 19);
padding-left: 8px;
font-weight: 300;
}
.footer-col .social-links a{
display: inline-block;
height: 40px;
width: 40px;
background-color: white;
margin:0 10px 10px 0;
text-align: center;
line-height: 40px;
border-radius: 50%;
color: rgb(198, 67, 19);
transition: all 0.5s ease;
        }
.footer-col .social-links a:hover{
color: rgb(19, 19, 108);
background-color:  rgb(198, 67, 19);
        }

/*responsive*/
@media(max-width: 767px){
.footer-col{
width: 50%;
margin-bottom: 30px;
}
}
@media(max-width: 574px){
.footer-col{
width: 100%;
}
}