.Nav-body{
    position: sticky;
}
.navbar .navbar-nav .nav-link::after{
    content: " ";
    color: black;
    display: block;
    width:0 ;
    height: 2px;
    background: transparent;
    transition: color 0.3s width 0.3s ease,background-color .3s ease;
}
.navbar .navbar-nav .nav-link:hover::after{
    width: 100%;
    background: rgb(198, 67, 19);
    color: white;
}
/* Dropdown Button */
.dropbtn{
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
position: relative;
display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
display: none;
position: absolute;
background-color: #f1f1f1;
width: 200px;
overflow-x: hidden;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
z-index: 1000;
}

/* Links inside the dropdown */
.dropdown-content p {
color: black;
padding: 4px 10px;
text-decoration: none;
display: block;
font-size: 14px;
}

/* Change color of dropdown links on hover */
.dropdown-content p:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}
