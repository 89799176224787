.Body{
    overflow-x: hidden;
}
::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-thumb{
    background-color: rgb(198, 67, 19);
}
#home{
    display: flex;
    align-items: center;
    height: 70vh;
    background: linear-gradient(rgb(0,0,0,0.3), rgb(0,0,0,0.2)), url("https://media.istockphoto.com/id/1370936815/photo/doing-handshake-with-customer-young-african-american-businessman-in-black-suit-is-indoors.jpg?b=1&s=170667a&w=0&k=20&c=cn_s0KkVYUKzML1qBgZtcH1gHY8ME2Zr0gtRkxe14Zg=");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
}

.bigtext{
    color: rgb(198, 67, 19);
}
.smalltext{
    color: white;
    font-weight: 400;
}
.productsBtn{
    background-color: rgb(198, 67, 19);
    width: 200px;
    padding: 9px 13px ;
    border-radius: 20px;
    color: white;

}
.productsBtn:hover{
    background-color: rgb(253, 90, 31);
}
#org{
    background-color: black;
    color: white;
}
#gain{
    background-color: white;
    color: black;
}
.homeImage{
    width: 270px;
    height: 200px;
    border-radius: 5px;
}
    /* whatsApp */
    .whatsapp_float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 40px;
        right: 60px;
        background-color: #25D366;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px white;
        z-index: 100;
    }
    .whatsapp-icon {
        margin-top: 16px;
    }

    /* for mobile */
    @media screen and (max-width: 767px){
        .whatsapp-icon {
        margin-top: 10px;
        }
        .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 50px;
        right: 30px;
        font-size: 22px;
        }
    }

    
@media(max-width: 767px){
    .homeImage{
        width: 450px;
        height: 300px;
    }
}
@media(max-width: 574px){
    .homeImage{
        width: 330px;
        height: 250px;
    }
}